// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "leaflet/dist/leaflet.css"
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility';

import "../css/backoffice.scss"; // Test

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

const $ = require('jquery');
//const $ = require('jquery');
// create global $ and jQuery variables
global.$ = window.$ = window.jQuery = $;

require('popper.js');
import "bootstrap";

const moment = require('moment');
global.moment = moment;


const select2 = require('select2');
global.select2 = select2;

global.I18n = require("i18n-js");


require("backoffice/Application.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
