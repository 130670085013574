export default class LocalityForm {

  constructor() {
    this.map = null;
    this.initial_latitude = null;
    this.initial_longitude = null;
    this.bounds = {};
    this.rectangle_bounds = null;
    window.application.setOnDataChangeListener(this);
    window.$("#locality_country_id").select2({
      allowClear: true,
      placeholder: I18n.t("backoffice.categories.form.country_placeholder")
    });

    this.bindEvents();
  }

  bindEvents() {
    var self = this;

    $(".bounds-control").unbind("change").change(function() {
      self.bounds = {
        sw_latitude: $("#locality_sw_latitude").val(),
        sw_longitude: $("#locality_sw_longitude").val(),
        ne_latitude: $("#locality_ne_latitude").val(),
        ne_longitude: $("#locality_ne_longitude").val()
      }

      var rawBounds = [ [
        self.bounds.ne_latitude,
        self.bounds.ne_longitude
      ], [
        self.bounds.sw_latitude,
        self.bounds.sw_longitude
      ] ];
      self.rectangle_bounds.setBounds(rawBounds);
      self.map.fitBounds(rawBounds);
    });

     $(".position-control").unbind("change").change(function() {
        self.position_marker.setLatLng([$("#locality_latitude").val(), $("#locality_longitude").val()]);
     });
  }

  initMap() {
    this.map = L.map('locality_map').setView([this.initial_latitude, this.initial_longitude], 16);

    L.tileLayer('https://tile.tracestrack.com/_/{z}/{x}/{y}.png?key=5b37c4cf109fa26755308c3a5e221369', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    if(this.bounds.ne_latitude!=null) {
      var rawBounds = [ [
        this.bounds.ne_latitude,
        this.bounds.ne_longitude
      ], [
        this.bounds.sw_latitude,
        this.bounds.sw_longitude
      ] ];
      this.rectangle_bounds = L.rectangle(rawBounds, {color: "#ff7800", weight: 1}).addTo(this.map);
      this.map.fitBounds(rawBounds);
    }

    this.position_marker = L.marker([this.initial_latitude, this.initial_longitude]).addTo(this.map);
  }


  onDataChanged(data) {
    this.initial_latitude = data.latitude;
    this.initial_longitude = data.longitude;

    this.bounds = data.bounds;

    this.initMap();
  }
}