
import "dropify/dist/css/dropify.css"

export default class AnnonceForm {

    constructor() {
        $("#ad_category_ids").select2({
            allowClear: true,
            placeholder: I18n.t("backoffice.annonces.form.categories")
        });
        const dropify = require("dropify");
        this.drop = new dropify(document.getElementById("ad_image"));
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        //$('.dropify').destroy();
    }

}